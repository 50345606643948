import React from 'react'

import Layout from '../components/layout'
/* eslint-disable */
const SocialPage = () => (
  <Layout articlelevel={100}>
    <>
      <div className="contentWrapper flex">
        <div className="content content--inverted">
          <p>
            <h3 className="roboto">Sociala medier:</h3>
          </p>
          <p>
            Vi finns i sociala medier mellan våra nummer. <br />
            Följ med oss ut i fält, på gårdsplaner, mässor och bakom kulisserna
            på redaktionen.
            <br />
            Facebook:{' '}
            <a
              style={{ color: 'white', textDecoration: 'underline' }}
              href="https://www.facebook.com/tidningenlantmannen/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tidningen Lantmannen
            </a>
            <br />
            Instagram:{' '}
            <a
              style={{ color: 'white', textDecoration: 'underline' }}
              href="https://www.instagram.com/tidningenlantmannen/?utm_source=ig_embed"
              target="_blank"
              rel="noopener noreferrer"
            >
              @tidningenlantmannen
            </a>
            <br />
            Twitter:{' '}
            <a
              style={{ color: 'white', textDecoration: 'underline' }}
              href="https://twitter.com/tidningenlm"
              target="_blank"
              rel="noopener noreferrer"
            >
              @tidningenLM
            </a>
            <br />
          </p>
          <iframe
            style={{
              verticalAlign: 'top',
              marginRight: '10px',
              maxWidth: '100%',
            }}
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftidningenlantmannen%2F&amp;tabs=timeline&amp;width=400&amp;height=600&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
            width="400"
            height="600"
            frameBorder="0"
            scrolling="no"
          />
          <iframe
            style={{ maxWidth: '100%' }}
            className="instagram-media instagram-media-rendered"
            id="instagram-embed-0"
            src="https://www.instagram.com/p/BRqVxaijFFu/embed/captioned/?cr=1&amp;v=7&amp;rd=https%3A%2F%2Flrfmediashop.se&amp;rp=%2Fkampanj%2Flantmannen-2%2F#%7B%22ci%22%3A0%2C%22os%22%3A4020.699999993667%7D"
            allowTransparency="true"
            allowFullScreen="true"
            frameBorder="0"
            height="730"
            data-instgrm-payload-id="instagram-media-payload-0"
            scrolling="no"
            width="400"
          />
        </div>
      </div>
    </>
  </Layout>
)

export default SocialPage
